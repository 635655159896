import access from './Access';
import course from './Course';
import homework from './Homework';
import lesson from './Lesson';
import order from './Order';
import user from './User';
import userCourseProgress from './UserCourseProgress';
import notificationSettings from './NotificationSettings';
import surveyAnswers from './SurveyAnswers';
import subscription from './Subscription';
import discount from './Discount';

export const dataService = {
  // access,
  course,
  homework,
  lesson,
  order,
  user,
  userCourseProgress,
  notificationSettings,
  surveyAnswers,
  subscription,
  discount,
};
