export function Color() {
  return (
<svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M60.667 60.665C59.7996 61.5276 58.6263 62.0122 57.403 62.013C56.7412 62.0164 56.0867 61.8754 55.485 61.5999C54.8833 61.3245 54.3489 60.9211 53.919 60.418L42.092 46.72L40.35 44.703L44.705 40.348L46.722 42.09L60.42 53.917C60.899 54.3315 61.2875 54.8402 61.5614 55.4115C61.8352 55.9827 61.9885 56.6042 62.0117 57.2373C62.0348 57.8703 61.9274 58.5014 61.6961 59.0911C61.4647 59.6808 61.1144 60.2166 60.667 60.665Z" fill="#A3A3F2"/>
<g opacity="0.15">
<path d="M46.722 42.09L42.092 46.72L40.35 44.703L44.705 40.348L46.722 42.09Z" fill="black"/>
</g>
<path d="M33.056 42.439L42.437 33.058L46.759 37.38C47.0339 37.6551 47.1884 38.0281 47.1884 38.417C47.1884 38.8059 47.0339 39.1789 46.759 39.454L39.453 46.761C39.3168 46.8973 39.1551 47.0054 38.977 47.0791C38.799 47.1529 38.6082 47.1908 38.4155 47.1908C38.2228 47.1908 38.032 47.1529 37.854 47.0791C37.676 47.0054 37.5143 46.8973 37.378 46.761L33.056 42.439Z" fill="#161616"/>
<path d="M39.785 39.787C31.606 47.965 18.298 47.965 10.119 39.787C1.93999 31.607 1.93999 18.3 10.119 10.121C18.298 1.94201 31.606 1.94201 39.785 10.121C47.964 18.3 47.964 31.608 39.785 39.787Z" fill="#161616"/>
<path d="M24.952 42.752C34.7816 42.752 42.75 34.7836 42.75 24.954C42.75 15.1244 34.7816 7.15601 24.952 7.15601C15.1224 7.15601 7.15399 15.1244 7.15399 24.954C7.15399 34.7836 15.1224 42.752 24.952 42.752Z" fill="#FDFCFC"/>
<path d="M38.215 18.73C37.087 16.3202 35.3234 14.2636 33.1139 12.7812C30.9043 11.2989 28.3325 10.4469 25.675 10.317V23.857L38.215 18.731V18.73Z" fill="#F8DB46"/>
<path d="M38.314 30.933C39.1655 29.048 39.6063 27.0034 39.607 24.935C39.607 23.227 39.3 21.595 38.763 20.071L26.688 25.008L38.314 30.933Z" fill="#ED7234"/>
<path d="M24.228 10.317C16.473 10.697 10.297 17.086 10.297 24.935C10.297 28.722 11.745 32.161 14.102 34.762L24.228 24.636V10.316V10.317Z" fill="#9F72FF"/>
<path d="M15.125 35.785C17.726 38.142 21.165 39.591 24.952 39.591C30.391 39.591 35.127 36.621 37.656 32.222L25.092 25.819L15.125 35.785Z" fill="#565CE7"/>
</svg>
  );
}
