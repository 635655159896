export function Google() {
  return (
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Social_Networks/Fill/Google">
<path id="Shape" fill-rule="evenodd" clip-rule="evenodd" d="M16.0353 7.45625C15.0028 6.4715 13.6249 5.93503 12.1992 5.95707C9.59036 5.95707 7.37468 7.71712 6.58467 10.0871C6.16578 11.3291 6.16578 12.674 6.58467 13.9159H6.58833C7.38201 16.2823 9.59403 18.0423 12.2029 18.0423C13.5496 18.0423 14.7057 17.6979 15.6018 17.0895V17.087C16.6563 16.3889 17.3765 15.2902 17.6007 14.0482H12.1992V10.1974H21.6315C21.7491 10.8662 21.8042 11.5496 21.8042 12.2294C21.8042 15.2709 20.7172 17.8424 18.8259 19.5841L18.8279 19.5856C17.1707 21.1142 14.8963 21.9997 12.1992 21.9997C8.41821 21.9997 4.96055 19.8685 3.26296 16.4917C1.84463 13.6661 1.84464 10.337 3.26297 7.51139C4.96056 4.1309 8.41821 1.9997 12.1992 1.9997C14.6831 1.9703 17.0825 2.90361 18.8904 4.6012L16.0353 7.45625Z" fill="currentColor"/>
</g>
</svg>

  );
}
