import Page, { EPageVariant } from 'ui/Page/Page';

import classes from './statis.module.scss';

export default function Oferta() {
  return (
    <Page variant={EPageVariant.WEB} header footer>
      <h3 className={classes.H3}>Оферта и договор</h3>
      <p className={classes.P}>Настоящие условия с вносимыми в них время от времени изменениями и дополнениями являются офертой, адресованной физическому лицу, которое достигло возраста 18 (восемнадцати) лет (далее – «Пользователь») и направленной на заключение пользовательского соглашения между Пользователем и Ульяновой Софией Романовной (далее – «Правообладатель») в связи с использованием Пользователем сервисов и функций сайта, размещенного в сети Интернет по адресу: http://flearn.net (далее – «Сайт»), ресурсах youtube.com, а также иных ресурсах со схожими доменными именами (далее – «Платформы»), исключительные права на которые полностью принадлежат Правообладателю (далее –«Соглашение»).</p>
      <p className={classes.P}>Соглашение находится в свободном доступе и доступно для любого Пользователя по адресу: https://flearn.net/oferta</p>
      <p className={classes.P}>Пользователь безоговорочно принимает условия оферты (акцептует оферту) при осуществлении регистрации (авторизации) на Сайте путем заполнения специальной формы для регистрации, путем использования Сервисов Сайта и Ресурсов, в том числе осуществляя оплату посредством Сайта (п. 3 ст. 438 ГК РФ) и отправляя заявку на предоставление информационно-консультационных услуг.</p>
      <p className={classes.P}>Пользователь выражает свое согласие на условия оферты в целом, без изменений и правок с его стороны.</p>
      <p className={classes.P}>При осуществлении регистрации на Сайте аккаунт Пользователя остается в безопасности, не нужно сообщать пароль от Учетной записи Пользователя каким-либо третьим лицам.</p>
      <h3 className={classes.H3}>ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h3>
      <p className={classes.P}>«Сайт»</p>
      <p className={classes.P}>— это комплекс объектов интеллектуальной собственности включающие в себя доменное имя (https://flearn.net), исходный код, интегрированные графические и дизайн-решения, а также программы для ЭВМ исключительные права на которые в полном объеме принадлежат Правообладателю, посредством которых он предоставляет право доступа Пользователю к Образовательным ресурсам.</p>
      <p className={classes.P}>«Образовательные ресурсы»</p>
      <p className={classes.P}>— это, включая, но не ограничиваясь, совокупность информации, видеокурсов, аудиозаписей, печатных и графических материалов, ресурсов и иных объектов интеллектуальной собственности исключительные права на которые в полном объеме принадлежат Правообладателю, которые он размещает на Сайте, а также Платформах Правообладателя, с целью предоставления доступа к ним Пользователю. Правообладатель также вправе направлять Образовательные ресурсы посредством электронной почты.</p>
      <p className={classes.P}>«Регистрация»</p>
      <p className={classes.P}>— процесс введения Пользователем необходимых данных посредством Сайта или Платформ для последующего предоставления доступа к Образовательным Ресурсам и функционалу Сайта.</p>
      <p className={classes.P}>«Пользователь»</p>
      <p className={classes.P}>— дееспособное физическое лицо, достигшее возраста 18 (восемнадцати) лет, использующее Платформы и функционал Сайта, в том числе путем осуществления регистрации через Сайт или Ресурсы (п. 3 ст. 438 ГК РФ) в соответствии с Соглашением и принявшее его условия.</p>
      <p className={classes.P}>«Код Сайта»</p>
      <p className={classes.P}>– любые элементы, находящиеся в основе Сайта, включая, в частности: исходные коды, сценарии, выходные программы, программное обеспечение, компьютерные программы, программные компоненты и другие комплекты заявлений и инструкций, содержащихся в основном Сайте.</p>
      <p className={classes.P}>«Оператор»</p>
      <p className={classes.P}>– Правообладатель и(или) любые аффилированные лица Правообладателя, а также его корпоративные консультанты, имеющие право на обработку персональных данных Пользователя, полученных при осуществлении регистрации в системе.</p>
      <p className={classes.P}>«Логин и Пароль»</p>
      <p className={classes.P}>– комбинация букв, цифр и графических символов необходимых для формирования учетной записи и осуществления входа на защищенные страницы Сайта, которые Правообладатель передает Пользователю посредством электронной почты в процессе Регистрации.</p>
      <p className={classes.P}>«Политика обработки персональных данных»</p>
      <p className={classes.P}>— документ в соответствии с которым осуществляется сбор, хранение и обработка персональных данных Пользователя, размещенный на Сайте.</p>
      <p className={classes.P}>«Учетная запись Пользователя»</p>
      <p className={classes.P}>– хранимая Правообладателем совокупность персональных данных о Пользователе и иной информации, сообщенной Пользователем Правообладателю при осуществлении регистрации на Сайте, которые необходимы Правообладателю для его опознавания и для предоставления доступа к Сайту и Платформам. Правообладатель оставляет за собой право запрашивать любую дополнительную информацию для однозначной идентификации Пользователя.</p>
      
      <h3 className={classes.H3}>1. ПРЕДМЕТ СОГЛАШЕНИЯ</h3>
      <p className={classes.P}>1.1 Правообладатель предоставляет Пользователю возможность использования Сайта и Платформ в объеме, предусмотренном условиями Соглашения. Деятельность Правообладателя включает в себя исключительную разработку Образовательных ресурсов и предоставление доступа к ним посредством Сайта и Ресурсов, и не подпадает под действия норм Федерального закона «Об образовании в Российской Федерации» от 29.12.2012 N 273-ФЗ.</p>
      <p className={classes.P}>1.2 Правообладатель предоставляет Пользователю возможность использовать Сайт и Платформы согласно условиям Соглашения, в полном объеме, включая, но не ограничиваясь, возможность создать Учетную запись Пользователя, а также пользоваться функционалом Сайта, знакомиться с Образовательными ресурсами и материалами Сайта и Платформ в соответствии с условиями Соглашения.</p>
      <p className={classes.P}>1.3 Правообладатель обязуется предоставить Пользователю неисключительные права (простую неисключительную лицензию) на использование Сайта и Образовательных ресурсов, включая использование программного обеспечения и функционала Сайта в порядке и в соответствии с условиями Соглашения.</p>
      <p className={classes.P}>Неисключительные права (простая неисключительная лицензия) на использование Сайта подразумевает предоставление права на использование программного обеспечения Сайта, позволяющего знакомиться и работать с Образовательными ресурсами, размещенными на Сайте и Платформах.</p>
      <p className={classes.P}>1.4 Правообладатель оставляет за собой право немотивированно отказаться от предоставления доступа к Образовательным ресурсам и функционалу Сайта в полном объеме.</p>
      <p className={classes.P}>1.5 Правообладатель не состоит с Пользователем в каких-либо гражданско-правовых или трудовых отношениях, не осуществляет услуги, связанные с трудоустройством временного или постоянного характера. Ничто в Соглашении не может пониматься как установление между Правообладателем и Пользователем агентских отношений, отношений товарищества, отношений по совместной деятельности, отношений личного найма или каких-либо иных отношений, помимо отношений по использованию Сайта Правообладателя.</p>

      <h3 className={classes.H3}>2. ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЯ</h3>
      <p className={classes.P}>2.1 Пользователь обязуется:</p>
      <p className={classes.P}>2.1.1 пройти процедуру Регистрации в системе;</p>
      <p className={classes.P}>2.1.2 предоставлять Правообладателю достоверную информацию о себе при Регистрации в системе;</p>
      <p className={classes.P}>2.1.3 при регистрации в качестве Пользователя не представляться чужим именем или не выступать от чужого имени, не использовать чужую электронную почту или электронную почту, на которую у Пользователя нет соответствующего права пользования, не размещать номера телефонов, право на использование которых отсутствует у Пользователя;</p>
      <p className={classes.P}>2.1.4 не сообщать Правообладателю, а также не размещать заведомо недостоверную или ложную информацию о себе и третьих лицах;</p>
      <p className={classes.P}>2.1.5 регулярно и своевременно обновлять контактную информацию в Учетной записи Пользователя самостоятельно или путем сообщения новой контактной информации Правообладателю;</p>
      <p className={classes.P}>2.1.6 самостоятельно нести негативные последствия, связанные с нарушением Соглашения, выражающиеся в блокировке Правообладателем Учетной записи Пользователя на определенный срок или удалении Учетной записи Пользователя;</p>
      <p className={classes.P}>2.1.7 не размещать на Сайте, в социальных сетях, а также на любых других источниках и ресурсах Образовательные ресурсы и их любые составные части, не осуществлять копирование Кода Сайта, его графических и дизайн решений, а также других объектов интеллектуальной собственности и их составных частей к которым в соответствии с настоящим Соглашением Пользователю предоставляется доступ.</p>
      <p className={classes.P}>2.1.8 сохранять конфиденциальность данных, необходимых для доступа к Учетной записи Пользователя. Пользователь несет полную ответственность за любое неправомерное использование Сайта. Пароль не может передаваться другим лицам, и Пользователь несет полную ответственность за весь ущерб, причиненный ему самому, Правообладателю и(или) третьим лицам, возникший вследствие намеренной или не намеренной передачи Пользователем Пароля третьему лицу;</p>
      <p className={classes.P}>2.1.9 своевременно, в полном объеме и в порядке, предусмотренном Соглашением, предоставлять вознаграждение Правообладателю;</p>
      <p className={classes.P}>2.1.10 в случае споров и(или) претензий третьих лиц относительно принадлежности прав на персональные и иные данные Пользователя, он обязуется разрешить такие споры и(или) претензии самостоятельно и за свой счет без привлечения Правообладателя;</p>
      <p className={classes.P}>2.1.11 не проводить действий с использованием Сайта, которые могут быть расценены как размещение, распространение, сохранение, загрузка и(или) уничтожение материалов (информации) в нарушение действующего законодательства Российской Федерации;</p>
      <p className={classes.P}>2.1.12 не размещать и(или) не передавать посредством Сайта рекламу, а также иную информацию в виде текста, изображения, звука или программного кода, которая может быть противозаконной, угрожающей, оскорбительной, клеветнической, заведомо ложной, грубой, непристойной, каким-либо образом вредить третьим лицам, а также нарушать их права и законные интересы;</p>
      <p className={classes.P}>2.1.13 не внедрять исполняемый код на стороне пользователя (java-script, visual basicscript и т.п.), любые внедряемые объекты (java-апплеты, flash и т.п.), использовать frame и iframe, каскадные таблицы стилей, используемые на Сайте, а также html-код, нарушающий оригинальный дизайн страниц Сайта;</p>
      <p className={classes.P}>2.1.14 не уничтожать и/или не изменять любые материалы на Сайте, автором которых Пользователь не является;</p>
      <p className={classes.P}>2.1.15 направлять на указанную Правообладателем электронную почту копии документов и/или информацию, запрашиваемую Правообладателем в соответствии с Соглашением;</p>
      <p className={classes.P}>2.1.16 при пользовании Сайта и Платформ соблюдать действующее законодательство Российской Федерации, а также нормы, установленные Соглашением.</p>
      <p className={classes.P}>2.2 Пользователь вправе:</p>
      <p className={classes.P}>2.2.1 использовать Сайт и Платформы в соответствии с условиями Соглашения;</p>
      <p className={classes.P}>2.2.2 самостоятельно выбирать и знакомиться с Образовательными материалами;</p>
      <p className={classes.P}>2.2.3 обращаться к Правообладателю за технической поддержкой и помощью по телефону и(или) по почте;</p>
      <p className={classes.P}>2.2.4 в любой момент удалить свою Учетную запись Пользователя путем направления Правообладателю сообщения на электронный адрес: ulianova.sofia@gmail.com.</p>
      <p className={classes.P}>2.3 Запрет на направление автоматических запросов: Пользователь не имеет права направлять автоматические запросы любого вида на Сайт без явного выраженного предварительного разрешения со стороны Правообладателя. Направление автоматических запросов включает, среди прочего:</p>
      <ul className={classes.UL}>
        <li>использование любого программного обеспечения, которое направляет запросы на Сайт для определения того, как веб-сайт или веб-страница ранжирует различные запросы;</li>
        <li>мета-поиск;</li>
        <li>осуществление поисков в автономном режиме на любом веб-сайте, принадлежащем Правообладателю.</li>
      </ul>

      <h3 className={classes.H3}>3. ПРАВА И ОБЯЗАННОСТИ ПРАВООБЛАДАТЕЛЯ</h3>
      <p className={classes.P}>3.1 Правообладатель обязуется предоставить Пользователю:</p>
      <p className={classes.P}>3.1.1 возможность зарегистрироваться (создать Учетную запись Пользователя) на Сайте в соответствии с условиями Соглашения и использовать Сайт и Платформы в полном объеме в соответствии с условиями Соглашения;</p>
      <p className={classes.P}>3.1.2 обязуется предоставить разъяснения или консультацию на предмет использования Сайта и Платформ по запросу Пользователя.</p>
      <p className={classes.P}>3.2 Правообладатель вправе:</p>
      <p className={classes.P}>3.2.1 вносить любые редакторские правки, изменения и(или) коррективы положения Соглашения;</p>
      <p className={classes.P}>3.2.2 вводить дополнительные сервисы, в том числе платные;</p>
      <p className={classes.P}>3.2.3 определять структуру наполнения Сайта, информацию, содержащуюся на Сайте;</p>
      <p className={classes.P}>3.2.4 предоставлять Пользователю дополнительные образовательные материалы и ресурсы путем их направления на адрес электронной почты Пользователя.</p>
      <p className={classes.P}>3.2.5 приостанавливать доступ к Сайту для проведения профилактических и регламентных работ на Сайте (технологические перерывы), но не чаще 1 (одного) раза в месяц в рабочие дни и не более чем на 24 (двадцать четыре) часа;</p>
      <p className={classes.P}>3.2.6 в любое время, по своему усмотрению, без объяснения причин и без извещения Пользователя, удалить Учетную запись Пользователя;</p>
      <p className={classes.P}>3.2.7 удалить или заблокировать Учетную запись Пользователя в случае нарушения последним любого из подпунктов, перечисленных в п. 2.1 Соглашения. В случае обнаружения или выявления противоправных действий Пользователя, передать информацию о Пользователе в правоохранительные органы Российской Федерации.</p>

      <h3 className={classes.H3}>4. МАРКЕТИНГОВЫЕ УСЛОВИЯ</h3>
      <p className={classes.P}>4.1 Правообладатель вправе предоставлять Пользователю скидки (снижает цену предоставления простой (неисключительной) лицензии) или предоставлять доступ Пользователю к Образовательным ресурсам на бесплатной основе при выполнении Пользователем определенных условий или наступления в отношении них определенных событий.</p>
      <p className={classes.P}>4.2 Условия предоставления скидки или бесплатного доступа, а также условия и события при соблюдении которых Правообладатель предоставляет скидку или бесплатный доступ к Образовательным ресурсам размещаются на Сайте в разделе акции по адресу: http://flearn.net.</p>

      <h3 className={classes.H3}>5. ФИНАНСОВЫЕ УСЛОВИЯ</h3>
      <p className={classes.P}>5.1 Внесение платы за предоставление доступа к Сайту и Образовательным ресурсам осуществляется Пользователем непосредственно в адрес Правообладателя в рамках выбранной сторонами Соглашения системы расчетов.</p>
      <p className={classes.P}>5.2 Оплата производится Пользователем при помощи функционала Сайта посредством интегрированной системы расчетов.</p>
      <p className={classes.P}>5.3 Правообладатель не несет ответственности за работу платежной системы.</p>
      <p className={classes.P}>5.4 Правообладатель вправе получать вознаграждение по Соглашению в формате единоразового или рекуррентных платежей за доступ к Образовательным ресурсам.</p>
      <p className={classes.P}>5.5 Размер оплаты, а также ее вид определяется исходя из объема предоставляемого доступа к Образовательным ресурсам в момент Регистрации (Акцепта) на Сайте, а также от вида конкретного Образовательного ресурса.</p>
      <p className={classes.P}>5.6 Размер оплаты может быть изменен Правообладателем в одностороннем порядке.</p>
      <p className={classes.P}>5.7 Использование Пользователем любых дополнительных Образовательных ресурсов предоставляемых Правообладателем с помощью Сайта и Платформ не включен в стоимость оплаты предусмотренной пунктом 5.5. Соглашения.</p>
      <p className={classes.P}>5.8 Правообладатель также вправе предоставить доступ к определенным Образовательным ресурсам на безвозмездной основе, в случае если Пользователем оставлена заявка на Сайте или иной Платформе на получение таких бесплатных ресурсов.</p>

      <h3 className={classes.H3}>6. ПЕРСОНАЛЬНЫЕ ДАННЫЕ ПОЛЬЗОВАТЕЛЯ</h3>
      <p className={classes.P}>6.1. Настоящим Пользователь свободно, своей волей и в своем интересе, предоставляет Правообладателю свои персональные данные при осуществлении Регистрации в системе для дальнейшего предоставления доступа к функционалу Сайта и Платформ, и дает согласие на любые действия (операции) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая, но не ограничиваясь, обработку, включая сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение соответствующих персональных данных Пользователя, для целей выполнения Правообладателем своих обязанностей по Соглашению;</p>
      <p className={classes.P}>6.1.1. персональные данные могут быть переданы в органы прокуратуры/правоохранительные органы по усмотрению Правообладателя и в той мере, в которой это запрашивается государственными органами, и в той степени, в которой это допускается в соответствии с применимым законодательством.</p>
      <p className={classes.P}>6.2. Обработка персональных данных Правообладателем осуществляется при помощи способов, обеспечивающих конфиденциальность таких данных, за исключением следующих случаев: (1) в случае обезличивания персональных данных; (2) в отношении общедоступных персональных данных; (3) в отношении персональных данных, добровольно указанных Пользователем при Регистрации в системе для дальнейшего использования Правообладателем и Заказчиками.</p>
      <p className={classes.P}>6.3. Настоящее согласие дано Пользователем и действует бессрочно и может быть отозвано посредством направления Правообладателю уведомления в простой письменной форме по адресу электронной почты sofisews@gmail.com. Уведомление об отзыве вступает в силу через 10 (Десять) дней после его получения Правообладателем. После получения уведомления Правообладатель обязуется удалить Учетную запись Пользователя и закрыть к ней доступ любых третьих лиц. Хранение удаленной Учетной записи Пользователя возможно только с целью резервного хранения.</p>
      <p className={classes.P}>6.4. Пользователь дает свое письменное согласие на все вышеперечисленные действия путем нажатия клавиши «оставить заявку», а также своими конклюдентными действиями по осуществлению Регистрации на Сайте и оплаты доступа к Образовательным ресурсам.</p>
      <p className={classes.P}>6.5. Пользователь соглашается на получение уведомлений от Правообладателя, в том числе содержащие Образовательные ресурсы, на адрес электронной почты, указанный в процессе Регистрации.</p>
      <p className={classes.P}>6.6. Пользователь в любое время вправе отказаться от получения уведомлений, отправив соответствующее сообщение на электронную почту: ulianova.sofia@gmail.com.</p>

      <h3 className={classes.H3}>7. ПРАВО СОБСТВЕННОСТИ НА ТОВАРНЫЕ ЗНАКИ, АВТОРСКИЕ ПРАВА, ПРАВА НА ИНТЕЛЛЕКТУАЛЬНУЮ СОБСТВЕННОСТЬ</h3>
      <p className={classes.P}>7.1. Сайт, Платформы, их содержимое (кроме Пользовательского Контента), Образовательные ресурсы, включая любые (все) интеллектуальные права, на любые (все) данные, информацию, тексты, графические и звуковые изображения или видеоматериалы, фотографии, дизайны, товарные знаки, фирменные наименования, URL-адреса и(или) контент, являющийся частью Сайта, содержащиеся на Сайте или доводимые до всеобщего сведения посредством Сайта (далее - «Контент»), Код Сайта, а также все исключительные и авторские права, права на товарные знаки, знаки обслуживания, фирменные наименования и другие права на объекты интеллектуальной собственности и права собственности на вышеуказанные объекты являются интеллектуальной собственностью Правообладателя и принадлежат Правообладателю и(или) его лицензиарам(-там) или контент-провайдерам и защищены соответствующими внутренними и международными актами об интеллектуальной собственности.</p>
      <p className={classes.P}>7.2. Кроме случаев, однозначно разрешенных Правообладателем в письменной форме, запрещается копирование, воспроизведение, исполнение, передача, продажа, лицензирование, изменение Контента, публичная демонстрация, интеграция с помощью перекрестных ссылок или вставок, распространение или эксплуатация полностью или частично Контента на любом другом веб-сайте или в иного рода объединенной в сети компьютерной среде, или с помощью любых средств, не известных на сегодняшний день, которые могут быть изобретены в дальнейшем (каждый случай нарушения настоящего положения считается «Несанкционированным использованием»).</p>
      <p className={classes.P}>7.3. Любое Несанкционированное использование является нарушением исключительных, авторских прав и других прав на интеллектуальную собственность Правообладателя (или) его лицензиаров(-тов) и контент-провайдеров, а также нарушением Соглашения. Последствием любого нарушения законодательства об интеллектуальной собственности могут стать санкции, предусмотренные законодательством Российской Федерации и международно-правовыми нормами в форме гражданско-правовой, административной и уголовной ответственности.</p>
      <p className={classes.P}>7.4. Правообладатель отвечает на уведомления о предполагаемых нарушениях права на интеллектуальную собственность и, после проведения расследования, удаляет Учетные записи Пользователей, допустивших неоднократные нарушения, и(или) блокирует доступ к ним, и(или) удаляет любые материалы, нарушающие права.</p>
      <p className={classes.P}>7.5. Если Пользователь добросовестно считает, что его произведение, средство индивидуализации или другой объект интеллектуальной собственности был воспроизведен любым образом или способом, который представляет собой нарушение права на интеллектуальную собственность, Пользователь обязан предоставить Правообладателю следующую информацию:</p>
      <ul className={classes.UL}>
        <li>имя, адрес, номер телефона и адрес электронной почты;</li>
        <li>описание произведения, защищенного авторским правом, или другого объекта интеллектуальной собственности, право на которое, по мнению Пользователя, было нарушено;</li>
        <li>выдержку или копию правоподтверждающего документа, указывающего на то, что Пользователь является обладателем исключительного права на произведение, средство индивидуализации или другой объект интеллектуальной собственности;</li>
        <li>описание места на Сайте, в котором расположен материал, нарушающий, по утверждению Пользователя, авторские права или другие права интеллектуальной собственности;</li>
        <li>заявление Пользователя, которое подтверждает, что оспариваемое использование не было разрешено обладателем исключительного права, его агентом или по закону;</li>
        <li>гарантии и заверения о том, что приведенная выше информация, содержащаяся в уведомлении Пользователя, является точной и полной, и что Пользователь является обладателем исключительного права или уполномочен действовать от имени обладателя такого права;</li>
        <li>электронную или собственноручную подпись обладателя исключительного права или лица, которое уполномочено действовать от имени обладателя такого права.</li>
      </ul>

      <h3 className={classes.H3}>8. ИСПОЛЬЗОВАНИЕ МАТЕРИАЛОВ САЙТА</h3>
      <p className={classes.P}>8.1 Любое несанкционированное использование Пользователем информации, размещенной на Сайте, элементов дизайна, оформления Сайта и его отдельных частей запрещается.</p>
      <p className={classes.P}>8.2 Санкционированное копирование и размещение элементов Сайта и Платформ, а также Образовательных ресурсов возможно исключительно с письменного согласия, заверенного печатью и подписью Правообладателя.</p>
      <p className={classes.P}>8.3 При правомерном копировании материалов Сайта в газетах, журналах и иных не электронных СМИ обязательно указание русского или английского фирменного наименования Правообладателя.</p>

      <h3 className={classes.H3}>9. ОТКАЗ ОТ ГАРАНТИЙ (ОГОВОРКИ), ОТВЕТСТВЕННОСТЬ</h3>
      <p className={classes.P}>9.1 Правообладатель не предоставляет Пользователю заверений и гарантий на предмет того, что Сайт, Платформы, Образовательные ресурсы и иные материалы: (1) отвечают его потребностям; (2) предоставляются бесперебойно, безопасным образом и без ошибок; (3) полученные результаты будут актуальными, точными, полными и достоверными.</p>
      <p className={classes.P}>9.2 Правообладатель ни при каких обстоятельствах не берет на себя обязательства по возмещению каких-либо убытков и упущенной выгоды, возникших в связи с использованием Пользователем Сайта, Платформ и Образовательных ресурсов.</p>
      <p className={classes.P}>9.3 Правообладатель прилагает все возможные усилия для того, чтобы исключить с Сайта и Платформ неактуальную, ложную или неполную информацию.</p>
      <p className={classes.P}>9.4 Правообладатель прилагает все усилия для обеспечения ежедневной бесперебойной работы Сайта и Платформ в круглосуточном режиме. Правообладатель не может гарантировать доступность Сайта и Платформ вследствие действий администраторов, операторов, провайдеров сети Интернет или проведения технических или регламентных работ на Сайте и Платформах. Правообладатель не несет ответственности за любые убытки и(или) ущерб, возникшие в результате вышеуказанных проблем доступа и особенностей технического обслуживания Сайта и Платформ.</p>
      <p className={classes.P}>9.5 Правообладатель не гарантирует, что программное обеспечение, серверы и компьютерные сети, используемые Сайтом и Платформами свободны от ошибок и компьютерных вирусов. Если использование Сайта и Платформ, повлекло за собой утрату данных или порчу оборудования, Правообладатель не несет за это ответственности.</p>
      <p className={classes.P}>9.6 Пользователь обязуется возместить Правообладателю ущерб в связи с любыми претензиями, судебными делами, основаниями для иска, требованиями и иными разбирательствами, возникающими из указанного ниже или в связи с нижеуказанным (далее – «Требования»): (1) неправомерное использование Пользователем Сайта и Платформ, невозможностью использования Сайта другими Пользователями, возникшей из-за действий Пользователя; (2) любое нарушение Соглашения со стороны Пользователя или посредством любой принадлежащей Пользователю Учетной записи; (3) нарушение Пользователем любых прав посетителя Сайта и Платформ, Пользователей или любого другого третьего лица. Кроме того, Пользователь обязуется возместить Правообладателю по его требованию любые суммы ущерба, убытков, расходов, судебных решений, сборов, штрафов и иных затрат, которые указанные выше лица могут понести в результате любого Требования (Требований).</p>
      <p className={classes.P}>9.7 Правообладатель не несет ответственности за любые прямые или косвенные убытки, которые нанесены Пользователю вследствие его Регистрации в системе, использования или невозможности использования Сервисов Правообладателя, изменения условий Соглашения, получения данных, сообщений, неуполномоченного доступа к Учетной записи Пользователя или изменения передаваемых им или хранящихся на сервере данных, заявлений или поведения любого лица на Сайте, а также в иных случаях, имеющих отношение к предмету Соглашения.</p>

      <h3 className={classes.H3}>10. РАЗРЕШЕНИЕ СПОРОВ</h3>
      <p className={classes.P}>10.1 Все споры, связанные с заключением, толкованием, исполнением и расторжением Соглашения, будут разрешаться Сторонами путем переговоров.</p>
      <p className={classes.P}>10.2 В случае недостижения соглашения в ходе переговоров, заинтересованная Сторона направляет претензию в письменной форме, подписанную уполномоченным лицом. Претензия должна быть направлена с использованием средств связи, обеспечивающих фиксацию ее отправления и получения (ценным письмом с описью вложения, телеграфом, электронным письмом с отсканированной и подписанной претензией и приложенными отсканированными копиями описи вложения в ценное письмо и квитанции об отправке с получением подтверждения от другой стороны о получении претензии в электронной форме, и т.д.), либо вручена другой Стороне под расписку.</p>
      <p className={classes.P}>10.3 К претензии должны быть приложены документы, обосновывающие предъявленные заинтересованной Стороной требования (в случае их отсутствия у другой Стороны), и оригинальные документы, подтверждающие полномочия лица, подписавшего претензию. Указанные документы представляются в форме надлежащим образом заверенных копий. Претензия, направленная без документов, подтверждающих полномочия лица, ее подписавшего, считается непредъявленной и рассмотрению не подлежит.</p>
      <p className={classes.P}>10.4 Сторона, которой направлена претензия, обязана рассмотреть полученную претензию и о результатах уведомить в письменной форме, аналогичной для направления претензии, заинтересованную Сторону в течение 7 (семи) рабочих дней со дня получения претензии.</p>
      <p className={classes.P}>10.5 В случае неурегулирования разногласий в претензионном порядке, а также в случае неполучения ответа на претензию в течение срока, указанного в п. 10.4 Соглашения, все споры, разногласия и требования, возникающие из Соглашения или связанные с ним прямо или косвенно, в том числе связанные с его заключением, изменением, исполнением, нарушением, расторжением, прекращением и действительностью, подлежат разрешению в суде г. Москвы.</p>

      <h3 className={classes.H3}>11. ФОРС-МАЖОРНЫЕ ОБСТОЯТЕЛЬСТВА</h3>
      <p className={classes.P}>11.1 Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение взятых по Соглашению обязательств, если такое неисполнение или ненадлежащее исполнение вызвано обстоятельствами непреодолимой силы, которые Стороны не могли заранее предвидеть и предусмотреть (форс-мажор). Стороны исходят из того, что к такими обстоятельствам относятся среди прочего: пожар, наводнение, землетрясение, гражданские волнения, революции, войны, совершение правонарушений и покушений на них, включая административные, уголовные и иные, действия должностных лиц органов государственной власти, акты государственной власти, эпидемии, эпизоотии, введение чрезвычайного и/или военного положения и иные обстоятельства, которые в соответствии с обычаями делового оборота признаются обстоятельствами непреодолимой силы.</p>
      <p className={classes.P}>11.2 В случае возникновения указанных в п. 11.1 Соглашения обстоятельств Сторона, для которой такие обстоятельства создают препятствия в исполнении обязательств, должна немедленно известить другую Сторону об их наличии в письменной форме, а также указать характер таких обстоятельств и предположительные сроки их прекращения.</p>
      <p className={classes.P}>11.3 Справка из компетентного органа (или организации), уполномоченного (уполномоченной) выдавать такие справки, либо в соответствии с обычаями делового оборота выдающего (выдающей) такие справки, будет считаться достаточным доказательством возникновения таких обстоятельств. Также стороны могут при необходимости ссылаться на сведения, размещенные в средствах массовой информации.</p>
      <p className={classes.P}>11.4 Стороны вправе расторгнуть Соглашение в одностороннем порядке, если указанные обстоятельства действуют более 30 (тридцати) дней, при этом другая Сторона не вправе требовать возмещения ущерба, выплаты штрафов и неустоек за неисполнение или ненадлежащее исполнение обязательств, которые имели место в период действия обстоятельств непреодолимой силы, но при этом обязательство по выплате арендной платы сохраняется и должно быть исполнено после окончания действий таких обстоятельств даже в случае одностороннего расторжения Соглашения.</p>
      <p className={classes.P}>11.5 Сроки исполнения обязательств, предусмотренные Соглашением, продлеваются на весь срок действия обстоятельств непреодолимой силы.</p>

      <h3 className={classes.H3}>12. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ, САЛЬВАТОРСКАЯ ОГОВОРКА</h3>
      <p className={classes.P}>12.1 Правообладатель имеет право уступить или передать Соглашение, а также любые, в том числе все, права, выгоды или обязательства по нему любому третьему лицу или лицам, при условии, что в таком случае Пользователь будет иметь право расторгнуть Соглашение. Пользователь не имеет право передавать свои права и обязанности по Соглашению третьему лицу.</p>
      <p className={classes.P}>12.2 Если по решению суда признается недействительным какое-либо положение Соглашения, Пользователь соглашается с тем, что такое положение будет считаться замененным на иное положение, которое реализует первоначальные намерения сторон (в той мере, в какой это разрешено законом). Если одно из положений Соглашения станет недействительным в связи с изменениями законодательства Российской Федерации, то это не является причиной для приостановки действия остальных положений. Недействительное положение должно быть заменено положением, допустимым в правовом отношении и близким по смыслу к измененному. Недействительность одного или нескольких положений Соглашения не влечет недействительности всего Соглашения, а также иных его положений.</p>

      <h3 className={classes.H3}>ПРАВООБЛАДАТЕЛЬ:</h3>
      <p className={classes.P}>Ульянова София Романовна</p>
      <p className={classes.P}>ИНН: 860105834109</p>
      <p className={classes.P}>e-mail: ulianova.sofia@gmail.com</p>
    </Page>
  );
}
