export function Composition() {
  return (
<svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M33 10.115V35.612H7.50503V27.151C6.98391 27.4323 6.40121 27.58 5.80903 27.581C5.34571 27.5817 4.88683 27.4909 4.45867 27.3138C4.03052 27.1368 3.64151 26.877 3.31394 26.5494C2.98638 26.2217 2.7267 25.8326 2.5498 25.4044C2.37289 24.9762 2.28224 24.5173 2.28303 24.054C2.28303 23.1188 2.65451 22.222 3.31577 21.5607C3.97702 20.8995 4.87387 20.528 5.80903 20.528C6.41903 20.528 6.99903 20.686 7.50503 20.958V12.375C7.50503 11.125 8.51503 10.115 9.76503 10.115H17.165C16.8701 9.57779 16.72 8.97304 16.7297 8.36025C16.7394 7.74746 16.9084 7.14775 17.2202 6.62013C17.532 6.0925 17.9758 5.65514 18.5079 5.35108C19.04 5.04702 19.6422 4.88674 20.255 4.886C20.8678 4.88716 21.4697 5.0478 22.0015 5.35213C22.5334 5.65646 22.9768 6.09398 23.2883 6.62167C23.5998 7.14936 23.7685 7.74904 23.7779 8.36172C23.7873 8.9744 23.6371 9.57898 23.342 10.116H33.002L33 10.115Z" fill="#ED7234"/>
<path d="M33 35.612V61.11H23.93C24.389 60.515 24.66 59.769 24.66 58.963C24.6604 58.4024 24.5272 57.8498 24.2713 57.351C24.0154 56.8522 23.6442 56.4216 23.1886 56.0949C22.733 55.7682 22.2061 55.5549 21.6516 55.4726C21.097 55.3903 20.5308 55.4415 20 55.6218C19.4692 55.8021 18.989 56.1063 18.5993 56.5093C18.2096 56.9124 17.9216 57.4025 17.7592 57.9391C17.5968 58.4756 17.5647 59.0432 17.6655 59.5947C17.7664 60.1461 17.9973 60.6656 18.339 61.11H9.76403C8.51403 61.11 7.50403 60.1 7.50403 58.85V35.612H33Z" fill="#565CE7"/>
<path d="M53.352 24.054C53.3516 24.6593 53.5071 25.2545 53.8034 25.7823C54.0998 26.3102 54.527 26.7528 55.044 27.0677C55.561 27.3825 56.1503 27.559 56.7552 27.58C57.3602 27.6011 57.9604 27.4661 58.498 27.188V35.612H33V27.151C32.4789 27.4323 31.8962 27.58 31.304 27.581C30.3689 27.581 29.472 27.2095 28.8108 26.5483C28.1495 25.887 27.778 24.9902 27.778 24.055C27.778 23.1198 28.1495 22.223 28.8108 21.5617C29.472 20.9005 30.3689 20.529 31.304 20.529C31.914 20.528 32.495 20.686 33 20.957V10.115H56.236C57.487 10.115 58.496 11.125 58.496 12.375V20.92C57.9582 20.6431 57.3583 20.509 56.7538 20.5306C56.1493 20.5523 55.5605 20.7289 55.0439 21.0435C54.5273 21.3581 54.1002 21.8002 53.8036 22.3274C53.5069 22.8546 53.3508 23.4491 53.35 24.054H53.352Z" fill="white"/>
<path d="M63.716 49.553C63.7164 50.0158 63.6255 50.474 63.4486 50.9017C63.2717 51.3293 63.0122 51.7178 62.685 52.045C62.3578 52.3722 61.9693 52.6317 61.5416 52.8086C61.114 52.9855 60.6557 53.0764 60.193 53.076C59.579 53.076 59.003 52.923 58.494 52.644V58.846C58.4953 59.1431 58.4379 59.4374 58.3251 59.7122C58.2123 59.9871 58.0463 60.2369 57.8367 60.4473C57.6271 60.6578 57.3779 60.8248 57.1036 60.9387C56.8292 61.0526 56.535 61.1111 56.238 61.111H49.422C49.9004 60.4963 50.1607 59.7399 50.162 58.961C50.1615 58.4977 50.0696 58.039 49.8918 57.6111C49.7139 57.1833 49.4535 56.7947 49.1254 56.4675C48.7973 56.1403 48.408 55.881 47.9796 55.7044C47.5513 55.5277 47.0923 55.4372 46.629 55.438C45.971 55.4384 45.3262 55.6229 44.7677 55.9707C44.2091 56.3185 43.7591 56.8157 43.4684 57.406C43.1778 57.9963 43.0582 58.6562 43.1231 59.311C43.1881 59.9658 43.435 60.5893 43.836 61.111H32.998V35.615H42.655C42.3698 35.0939 42.2211 34.509 42.223 33.915C42.2223 33.4517 42.3129 32.9929 42.4896 32.5647C42.6663 32.1364 42.9257 31.7472 43.2528 31.4192C43.58 31.0912 43.9686 30.8309 44.3963 30.6531C44.8241 30.4753 45.2827 30.3835 45.746 30.383C46.3594 30.3835 46.9622 30.5435 47.4951 30.8475C48.028 31.1514 48.4727 31.5887 48.7854 32.1165C49.0981 32.6443 49.2681 33.2444 49.2787 33.8577C49.2894 34.4711 49.1402 35.0767 48.846 35.615H58.494V46.453C59.0316 46.1584 59.6367 46.009 60.2497 46.0195C60.8626 46.0301 61.4623 46.2002 61.9894 46.5131C62.5166 46.8261 62.9531 47.271 63.2558 47.8041C63.5586 48.3372 63.7172 48.9399 63.716 49.553Z" fill="#F8DB46"/>
<g opacity="0.1">
<path d="M49.29 35.608V35.618H48.848V35.608H49.29Z" fill="#F8DB46"/>
</g>
<path d="M58.496 35.608H49.29C51.4838 31.214 52.6239 26.3693 52.62 21.458C52.62 17.474 51.881 13.654 50.518 10.112H56.24C56.8395 10.1133 57.4139 10.3527 57.8369 10.7774C58.26 11.2022 58.497 11.7775 58.496 12.377V20.921C57.9944 20.6615 57.4377 20.5263 56.873 20.527C56.3393 20.5279 55.8128 20.6499 55.3332 20.8838C54.8535 21.1177 54.4332 21.4574 54.1038 21.8772C53.7745 22.2971 53.5447 22.7863 53.4318 23.3079C53.3189 23.8294 53.3258 24.3698 53.4521 24.8883C53.5783 25.4068 53.8206 25.8899 54.1606 26.3012C54.5006 26.7125 54.9295 27.0413 55.415 27.2628C55.9006 27.4843 56.43 27.5928 56.9635 27.58C57.497 27.5672 58.0206 27.4335 58.495 27.189L58.496 35.608Z" fill="black" fill-opacity="0.15"/>
<path d="M63.718 49.556C63.7184 50.0188 63.6275 50.477 63.4506 50.9047C63.2737 51.3323 63.0142 51.7208 62.687 52.048C62.3598 52.3752 61.9713 52.6347 61.5437 52.8116C61.116 52.9885 60.6577 53.0794 60.195 53.079C59.58 53.079 59.005 52.925 58.495 52.647V58.848C58.4964 59.1451 58.4392 59.4395 58.3265 59.7143C58.2138 59.9892 58.048 60.2391 57.8384 60.4496C57.6289 60.6602 57.3798 60.8273 57.1055 60.9413C56.8312 61.0553 56.5371 61.114 56.24 61.114H49.424C49.9024 60.4993 50.1627 59.7429 50.164 58.964C50.1635 58.5007 50.0717 58.042 49.8938 57.6141C49.7159 57.1863 49.4555 56.7977 49.1274 56.4705C48.7993 56.1433 48.41 55.884 47.9816 55.7074C47.5533 55.5307 47.0943 55.4402 46.631 55.441C45.9729 55.4412 45.328 55.6256 44.7693 55.9733C44.2106 56.321 43.7604 56.8182 43.4697 57.4086C43.1789 57.9989 43.0592 58.6589 43.1241 59.3137C43.189 59.9686 43.436 60.5922 43.837 61.114H23.927C24.388 60.509 24.657 59.77 24.657 58.964C24.6578 58.5007 24.5673 58.0417 24.3906 57.6134C24.214 57.185 23.9547 56.7957 23.6275 56.4676C23.3003 56.1395 22.9117 55.8791 22.4839 55.7012C22.056 55.5233 21.5973 55.4315 21.134 55.431C20.6707 55.4315 20.212 55.5233 19.7841 55.7012C19.3563 55.8791 18.9677 56.1395 18.6405 56.4676C18.3133 56.7957 18.054 57.185 17.8774 57.6134C17.7007 58.0417 17.6102 58.5007 17.611 58.964C17.611 59.77 17.88 60.509 18.341 61.114H9.76799C9.47043 61.1141 9.17575 61.0556 8.90083 60.9418C8.6259 60.8279 8.37611 60.661 8.16575 60.4505C7.95538 60.2401 7.78857 59.9902 7.67485 59.7152C7.56113 59.4403 7.50273 59.1456 7.50299 58.848V53.905C10.5412 54.6681 13.6624 55.0517 16.795 55.047C22.622 55.047 28.132 53.741 32.999 51.418C40.141 48.02 45.901 42.443 49.289 35.618H58.496V46.455C59.0336 46.1604 59.6387 46.011 60.2517 46.0215C60.8646 46.0321 61.4643 46.2022 61.9914 46.5152C62.5186 46.8281 62.9551 47.273 63.2578 47.8061C63.5606 48.3392 63.7192 48.9429 63.718 49.556Z" fill="black" fill-opacity="0.15"/>
</svg>
  );
}
