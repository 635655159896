export function ColorEye() {
  return (
<svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M63.659 32.994C63.659 33.239 63.659 33.484 63.638 33.729C63.638 34.23 63.616 34.72 63.574 35.199C62.753 46.807 55.473 56.624 45.315 61.079C44.601 61.389 43.855 61.676 43.109 61.943C42.117 62.295 41.105 62.593 40.071 62.828C40.061 62.838 40.05 62.838 40.039 62.838C37.779 63.382 35.424 63.659 33.004 63.659C22.345 63.659 12.954 58.223 7.46602 49.962C7.37002 49.813 7.27402 49.675 7.18802 49.525C7.08202 49.376 6.98602 49.216 6.89002 49.067C3.91275 44.2352 2.34064 38.6694 2.35002 32.994C2.35002 27.494 3.79902 22.324 6.34602 17.869V17.858C11.612 8.585 21.578 2.338 33.004 2.338C36.734 2.338 40.316 3.01 43.62 4.236C45.6588 4.97822 47.6077 5.94731 49.43 7.125C49.43 7.125 49.44 7.125 49.44 7.135C53.6833 9.81992 57.1983 13.5107 59.673 17.88V17.891C62.2929 22.4932 63.6668 27.6983 63.659 32.994Z" fill="#F8DB46"/>
<path d="M50.293 26.8C49.6673 27.3733 49.0312 27.935 48.385 28.485L48.375 28.495C28.345 45.87 7.46398 49.963 7.46398 49.963L6.88898 49.068C3.91172 44.2362 2.3396 38.6704 2.34898 32.995C2.34898 27.495 3.79798 22.325 6.34498 17.87V17.859C6.37698 17.838 6.40898 17.827 6.43098 17.816C19.754 10.398 32.651 13.073 40.614 16.26C40.634 16.27 40.667 16.281 40.688 16.292C42.8202 17.1395 44.8866 18.1443 46.87 19.298C48.075 20.001 48.725 20.47 48.725 20.47L50.292 26.802L50.293 26.8Z" fill="#ED7234"/>
<path d="M45.315 61.08C44.601 61.389 43.855 61.676 43.109 61.943C42.117 62.295 41.105 62.593 40.071 62.828C40.061 62.838 40.05 62.838 40.039 62.838C37.779 63.382 35.424 63.659 33.004 63.659C22.345 63.659 12.954 58.223 7.46599 49.962C7.36999 49.813 7.27399 49.675 7.18799 49.525L49.003 28.07H49.013L51.487 26.802L56.144 29.285L56.283 29.36L56.315 29.84V29.85C57.402 48.023 50.229 57.051 45.315 61.08Z" fill="#565CE7"/>
<path d="M63.638 33.729C63.638 34.23 63.616 34.72 63.574 35.199C62.7 47.629 54.407 58.009 43.109 61.943C43.141 61.633 43.183 61.303 43.237 60.963C44.718 50.996 53.49 30.286 53.49 30.286L53.82 30.03L53.831 30.02L53.842 30.01L54.822 29.22L57.839 26.8C57.999 26.875 58.169 26.95 58.329 27.046C58.372 27.067 58.404 27.099 58.436 27.121C58.4773 27.1418 58.5165 27.1666 58.553 27.195H58.563C61.133 28.858 63.393 33.249 63.638 33.729Z" fill="#F8DB46"/>
<path d="M63.659 32.994C63.659 33.239 63.659 33.484 63.638 33.729C63.638 34.23 63.616 34.72 63.574 35.199C62.401 32.855 60.131 28.57 58.543 27.216C58.5 27.185 58.468 27.153 58.436 27.121C58.404 27.099 58.372 27.067 58.329 27.046C58.1846 26.931 58.0176 26.8475 57.839 26.801L56.944 20.725L58.628 18.967L58.638 18.956L59.673 17.89C62.2928 22.4922 63.6668 27.6983 63.659 32.994Z" fill="#ED7234"/>
<path d="M59.667 17.882V17.892L58.633 18.959L56.939 20.729L52.931 18.618L51.62 17.925L50.65 16.326L49.328 14.141L43.615 4.239C45.6538 4.98089 47.6027 5.94964 49.425 7.127C49.425 7.127 49.435 7.127 49.435 7.138C53.6778 9.82276 57.1924 13.5132 59.667 17.882Z" fill="#565CE7"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M58.6606 22.4972C59.5465 26.0438 57.8973 29.5102 54.9771 30.2397C52.0569 30.9691 48.9714 28.6854 48.0855 25.1388C47.1996 21.5922 48.8487 18.1258 51.7689 17.3963C54.6892 16.6669 57.7747 18.9506 58.6606 22.4972ZM56.0071 23.16C56.4484 24.9266 55.6268 26.6533 54.1721 27.0167C52.7173 27.3801 51.1803 26.2426 50.739 24.476C50.2977 22.7094 51.1193 20.9827 52.574 20.6193C54.0288 20.2559 55.5658 21.3934 56.0071 23.16Z" fill="#161616"/>
<g opacity="0.15">
<path d="M55.996 53.285C50.382 59.647 42.161 63.66 33.001 63.66C16.071 63.66 2.341 49.93 2.341 33C2.341 23.84 6.356 15.62 12.718 10.005C7.7704 15.603 5.04376 22.8189 5.053 30.29C5.053 47.23 18.773 60.95 35.713 60.95C43.483 60.95 50.583 58.056 55.996 53.285Z" fill="black"/>
</g>
</svg>
  );
}