export function Style() {
  return (
<svg viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.2151 32.552L20.2051 48.304V63.212H15.4921V48.304L15.5021 32.552H20.2151Z" fill="#2E33AD"/>
<g opacity="0.1">
<path d="M15.4911 48.304H20.2051V63.212H15.4911V48.304Z" fill="#ED7234"/>
</g>
<path d="M14.031 50.986L14.024 62.444C14.024 62.868 14.367 63.211 14.791 63.211H20.898C21.1011 63.2107 21.2958 63.13 21.4394 62.9864C21.583 62.8428 21.6638 62.6481 21.664 62.445L21.671 50.987C21.671 50.7836 21.5902 50.5885 21.4464 50.4447C21.3026 50.3008 21.1075 50.22 20.904 50.22H14.797C14.5939 50.22 14.3991 50.3007 14.2554 50.4444C14.1118 50.588 14.031 50.7828 14.031 50.986Z" fill="#161616"/>
<path d="M45.2081 19.258C43.7797 20.6883 42.0832 21.8229 40.2157 22.5966C38.3483 23.3703 36.3465 23.768 34.3251 23.767C34.3251 15.27 41.2211 8.375 49.7171 8.375C49.7181 10.3964 49.3204 12.3982 48.5467 14.2657C47.7729 16.1331 46.6384 17.8296 45.2081 19.258Z" fill="#ED7234"/>
<path d="M23.4431 19.258C24.8714 20.6883 26.5679 21.8229 28.4354 22.5966C30.3029 23.3703 32.3046 23.768 34.3261 23.767C34.3261 15.27 27.4311 8.375 18.9351 8.375C18.9339 10.3963 19.3315 12.3981 20.105 14.2655C20.8786 16.133 22.0129 17.8296 23.4431 19.258Z" fill="#F8DB46"/>
<path d="M38.8321 12.883C38.8321 16.821 37.3251 20.759 34.3251 23.767C28.3171 17.759 28.3171 8.007 34.3251 2C37.3251 5.007 38.8321 8.945 38.8321 12.883Z" fill="#DDA411"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M39.55 35.34C44.072 38.748 48.632 36.194 48.632 36.194C60.359 30.902 58.635 16.385 58.635 16.385C55.15 21.059 46.305 18.717 46.305 18.717C36.599 17.399 34.365 22.637 34.365 22.637C34.365 22.637 32.124 17.398 22.418 18.718C22.418 18.718 13.573 21.059 10.088 16.384C10.088 16.384 8.37204 30.902 20.092 36.194C20.092 36.194 24.651 38.748 29.18 35.34C29.18 35.34 29.707 34.867 30.98 34.41C33.1646 33.6171 35.5584 33.6171 37.743 34.41C39.016 34.867 39.55 35.34 39.55 35.34ZM41.7061 30.513C42.4098 30.6714 43.1287 30.7533 43.8501 30.757C45.7421 30.757 47.4631 30.135 48.9651 28.909C50.1081 27.976 51.0571 26.629 51.7091 25.016C51.8961 24.555 52.0861 24.123 52.2791 23.72C51.5061 23.896 50.9731 23.922 50.8551 23.926L50.5821 23.935L50.3151 23.874C49.3191 23.645 48.3631 23.528 47.4731 23.528C42.8191 23.528 40.2301 26.82 38.5871 29.792C39.6552 29.886 40.705 30.1286 41.7061 30.513ZM27.0181 30.513C26.2811 30.675 25.5611 30.757 24.8741 30.757C22.9821 30.757 21.2611 30.135 19.7591 28.909C18.6151 27.976 17.6671 26.629 17.0151 25.016C16.8281 24.555 16.6381 24.123 16.4451 23.72C16.9126 23.8314 17.3892 23.9004 17.8691 23.926L18.1421 23.935L18.4091 23.874C19.4051 23.645 20.3611 23.528 21.2511 23.528C25.9051 23.528 28.4941 26.82 30.1371 29.792C29.0689 29.886 28.0191 30.1286 27.0181 30.513Z" fill="#565CE7"/>
<path d="M43.8501 30.757C43.1287 30.7533 42.4098 30.6714 41.7061 30.513C40.705 30.1286 39.6552 29.886 38.5871 29.792C40.2301 26.82 42.8191 23.528 47.4731 23.528C48.3631 23.528 49.3191 23.645 50.3151 23.874L50.5821 23.935L50.8551 23.926C50.9731 23.922 51.5061 23.896 52.2791 23.72C52.0861 24.123 51.8961 24.555 51.7091 25.016C51.0571 26.629 50.1081 27.976 48.9651 28.909C47.4631 30.135 45.7421 30.757 43.8501 30.757ZM24.8741 30.757C25.5611 30.757 26.2811 30.675 27.0181 30.513C28.0191 30.1286 29.0689 29.886 30.1371 29.792C28.4941 26.82 25.9051 23.528 21.2511 23.528C20.3611 23.528 19.4051 23.645 18.4091 23.874L18.1421 23.935L17.8691 23.926C17.3892 23.9003 16.9126 23.8314 16.4451 23.72C16.6381 24.123 16.8281 24.555 17.0151 25.016C17.6671 26.629 18.6151 27.976 19.7591 28.909C21.2611 30.135 22.9821 30.757 24.8741 30.757Z" fill="#ECBA38"/>
</svg>

  );
}
