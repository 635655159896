export function Image() {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_2974_4063" maskUnits="userSpaceOnUse" x="0" y="2" width="25" height="21">
        <path d="M8.59717 8.71639C8.59717 10.0971 7.52265 11.2164 6.19717 11.2164C4.87169 11.2164 3.79717 10.0971 3.79717 8.71639C3.79717 7.33568 4.87169 6.21639 6.19717 6.21639C7.52265 6.21639 8.59717 7.33568 8.59717 8.71639Z" fill="currentColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.000488281 4.50028C0.000488281 3.11957 1.07501 2.00029 2.40049 2.00029H21.6005C22.926 2.00029 24.0005 3.11957 24.0005 4.50028V19.5003C24.0005 20.881 22.926 22.0003 21.6005 22.0003H20.0163C20.011 22.0003 20.0058 22.0003 20.0005 22.0003H2.94747C2.94295 22.0003 2.93843 22.0003 2.93391 22.0003H2.40049C1.07501 22.0003 0.000488281 20.881 0.000488281 19.5003V4.50028ZM2.40049 3.87528H21.6005C21.9319 3.87528 22.2005 4.15511 22.2005 4.50028V12.2904L16.9862 7.00031L8.11509 16.0003L5.50191 13.3491L1.80049 17.1044V4.50028C1.80049 4.1551 2.06912 3.87528 2.40049 3.87528Z" fill="currentColor"/>
      </mask>
      <g mask="url(#mask0_2974_4063)">
      < rect x="0.000488281" y="-0.000549316" width="24" height="24" fill="currentColor"/>
      </g>
    </svg>
  );
}
